import React from "react";
import { Eye, EyeOff } from "react-feather";
import { useAuth } from "../hooks/useAuth";

const Sign = () => {
  document.title = "WT Admin - Sign";
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const { login, token, setToken, error, setError, username, setUsername } = useAuth();

  const auth = window.sessionStorage.getItem("auth");
  if (auth) window.location.href = "/dashboard?view=users";

  return (
    <section className="auth_page">
      <main className="auth_container">
        <div className="auth_info">
          <h1 className="auth_title">WebTrade Admin</h1>
          <p className="auth_description">
            Enter token to get access to the admin panel
          </p>
        </div>
        <form className="auth_form">
          <div className="auth_form_group">
            <div className="password_input">
				<input
				  onFocus={() => setError(null)}
				  type="text"
				  id="username"
				  name="username"
				  onChange={(e) => setUsername(e.target.value)}
				  value={username}
				  placeholder="Enter your username"
				/>
				</div>
            <div className="password_input">
              <input
                onFocus={() => setError(null)}
                type={passwordVisible ? "text" : "password"}
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="off"
                autoSave="off"
                id="token"
                name="token"
                onChange={(e) => setToken(e.target.value)}
                value={token}
                placeholder="Enter your token"
              />
              <div className="eye_container">
                {passwordVisible ? (
                  <EyeOff
                    style={{ cursor: "pointer" }}
                    color="rgba(119, 237, 145, .2)"
                    className="password_toggle"
                    onClick={() => setPasswordVisible(false)}
                  />
                ) : (
                  <Eye
                    style={{ cursor: "pointer" }}
                    color="rgba(119, 237, 145, 1)"
                    className="password_toggle"
                    onClick={() => setPasswordVisible(true)}
                  />
                )}
              </div>
            </div>
          </div>
          {error && <p className="error">{error}</p>}
          <button onClick={login}>Get access</button>
        </form>
      </main>
    </section>
  );
};

export default Sign;
