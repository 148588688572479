import React from "react";
import { useAuth, useAdmin } from "./useAuth";

const useWalletsDeposits = (type) => {
  const [wallets, setWallets] = React.useState([]);
  const [pendingWallets, setPendingWallets] = React.useState([]);
  const [approvedWallets, setApprovedWallets] = React.useState([]);
  const [rejectedWallets, setRejectedWallets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const server_url = "https://webtrade.onrender.com";
  const route = type === "Deposits" ? "/deposits" : "/withdraws";
  const { admin, isAdmin } = useAdmin();
  const { createAdminAction } = useAuth();

  const getWallets = async () => {
    try {
      const response = await fetch(server_url + route);
      const json = await response.json();
      setWallets(json);
    } catch (err) {}
    setLoading(false);
  };

  const approveWallet = async (wallet, user) => {
    const user_route = "/users";
    // approve wallet
    try {
      wallet.status = "approved";
      const response = await fetch(server_url + route, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: wallet?._id,
          status: "approved",
        }),
      });

      const user_response = await fetch(server_url + user_route, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: user?._id,
          totalBalance:
            type === "Deposits"
              ? (user?.totalBalance || 0) + wallet?.amount
              : user?.totalBalance - wallet?.amount,
        }),
      });
      const user_json = await user_response.json();
      !isAdmin &&
        createAdminAction(
          admin.id,
          admin.name,
          admin.name +
            " has approved a " +
            (type === "Deposits" ? "deposit" : "withdrawal") +
            " of " +
            wallet.amount +
            " for " +
            user.name +
            " with a new balance of " +
            user_json.totalBalance
        );
      getWallets();
    } catch (err) {}
  };

  const rejectWallet = async (wallet, user) => {
    // reject wallet
    try {
      wallet.status = "rejected";
      const response = await fetch(server_url + route, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: wallet?._id,
          status: wallet?.status,
        }),
      });
      const json = await response.json();
      !isAdmin &&
        createAdminAction(
          admin.id,
          admin.name,
          admin.name +
            " has rejected a " +
            (type === "Deposits" ? "deposit" : "withdrawal") +
            " of " +
            wallet.amount +
            " for " +
            user.name
        );
      getWallets();
    } catch (err) {}
  };

  const deleteWallet = async (id) => {
    // delete wallet
    try {
      const response = await fetch(server_url + route + "/" + id, {
        method: "DELETE",
      });
      const json = await response.json();
      getWallets();
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getWallets();
  }, []);

  React.useEffect(() => {
    if (wallets?.length > 0) {
      setPendingWallets(
        wallets?.filter((wallet) => wallet.status === "pending")
      );
      setApprovedWallets(
        wallets?.filter((wallet) => wallet.status === "approved")
      );
      setRejectedWallets(
        wallets?.filter((wallet) => wallet.status === "rejected")
      );
    } else {
      setPendingWallets([]);
      setApprovedWallets([]);
      setRejectedWallets([]);
    }
  }, [wallets]);

  return {
    loading,
    wallets,
    getWallets,
    approveWallet,
    rejectWallet,
    deleteWallet,
    pendingWallets,
    approvedWallets,
    rejectedWallets,
  };
};

export default useWalletsDeposits;
