import React from "react";
import EditModal from "./EditModal";
import UsersList from "./UsersList";
import useUsers from "../../hooks/useUsers";

const Users = ({users, getUsers}) => {
  const [emodal, setEmodal] = React.useState(false);
  const [cmodal, setCmodal] = React.useState(false);
  const [user, setUser] = React.useState(null);

  const editModal = (u) => {
    setUser(u);
    setEmodal(true);
  };

  const createModal = () => {
    setUser({});
    setCmodal(true);
  };

//   const { users, loading, error, getUsers } = useUsers();

  return (
    <div className="users_list_wrapper">
      {emodal && (
        <EditModal
          refresh={getUsers}
          user={user}
          closeModal={() => setEmodal(false)}
        />
      )}
      {cmodal && (
        <EditModal
          refresh={getUsers}
          user={user}
          closeModal={() => setCmodal(false)}
          create={true}
        />
      )}
      <div className="users_header">
        <h1 className="users_header_title">Users</h1>
        <div className="header_cta">
          <button className="users_header_button" onClick={getUsers}>
            Refresh
          </button>
          <button className="users_header_button" onClick={createModal}>
            Create User
          </button>
        </div>
      </div>
      <UsersList users={users} refresh={getUsers} editModal={editModal} />
    </div>
  );
};

export default Users;
