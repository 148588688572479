import React from "react";
import useUsers from "../../hooks/useUsers";
import { useAdmin } from "../../hooks/useAuth";

const UsersListHeader = () => {
  return (
    <div className="users_list_header">
      <div className="item_wrapper">
        <p className="users_list_header_item">Username</p>
      </div>
      <div className="item_wrapper">
        <p className="users_list_header_item">Email</p>
      </div>
      <div className="item_wrapper">
        <p className="users_list_header_item">Verified</p>
      </div>
      <div className="item_wrapper">
        <p className="users_list_header_item">Total Balance</p>
      </div>
      <div className="item_wrapper">
        <p className="users_list_header_item">Actions</p>
      </div>
    </div>
  );
};

const UsersListItem = ({ user, refresh, editModal, deleteModal }) => {
  const { deleteUser } = useUsers();

  const handleDelete = async () => {
    await deleteUser(user._id);
    refresh();
  };

  const { isAdmin } = useAdmin();
  return (
    <div className="users_list_item">
      <div className="item_wrapper">
        <p className="users_list_item_text">{user.name}</p>
      </div>
      <div className="item_wrapper">
        <p className="users_list_item_text">{user.email}</p>
      </div>
      <div className="item_wrapper">
        <p className="users_list_item_text">{user.verified ? "Yes" : "No"}</p>
      </div>
      <div className="item_wrapper">
        <p className="users_list_item_text">{user.totalBalance || 0}</p>
      </div>
      <div className="item_wrapper">
        <button
          className="users_list_item_button"
          onClick={() => editModal(user)}
        >
          Edit
        </button>
        {isAdmin && (
          <button className="users_list_item_button" onClick={handleDelete}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

const UsersList = ({ users, refresh, editModal }) => {
  const [search, setSearch] = React.useState("");
  const [filteredUsers, setFilteredUsers] = React.useState([]);

  const searchHandler = (e) => {
    setSearch(e.target.value);
    setFilteredUsers(
      users.filter(
        (user) =>
          user?.name
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase()) ||
          user?.email?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      )
    );
  };

  return (
    <div className="users_list">
      <input
        type="text"
        className="search"
        placeholder="Search by username or email"
        value={search}
        onChange={searchHandler}
      />
      <UsersListHeader />
      <div className="users_list_body">
        {filteredUsers.length > 0
          ? filteredUsers.map((user) => (
              <UsersListItem
                user={user}
                refresh={refresh}
                editModal={editModal}
                key={user._id}
              />
            ))
          : users.map((user) => (
              <UsersListItem
                user={user}
                refresh={refresh}
                editModal={editModal}
                key={user._id}
              />
            ))}
      </div>
    </div>
  );
};

export default UsersList;
