import React, { useEffect } from "react";
import { CreateAdminModal } from "./index";
import { useAuth } from "../../hooks/useAuth";
import useUsers from "../../hooks/useUsers";

const AdminUsersItem = ({ user, assignUser, adminId }) => {
  const isAssigned = user.admins.filter((item) => item === adminId).length > 0;
  if (user.admins.length !== 0 && !isAssigned) return null;
  return (
    <>
      <p>{user._id}</p>
      <p>{user.name}</p>
      {isAssigned ? (
        <button
          className="edit_modal_form_button"
          onClick={() => assignUser(user, "remove")}
        >
          Remove
        </button>
      ) : (
        <button
          className="edit_modal_form_button"
          onClick={() => assignUser(user, "add")}
        >
          Assign
        </button>
      )}
    </>
  );
};

const AssignUsersModal = ({ getUsers, users, closeModal, adminId }) => {
  const assignUser = async (user, action) => {
    const server_url = "https://webtrade.onrender.com";
    const route = "/admin/assign";

    const res = await fetch(server_url + route, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: user._id,
        admin: adminId,
        action,
      }),
    });

    if (res.status === 200) {
		getUsers();
    }
  };

  return (
    <div className="modal_wrapper" onClick={closeModal}>
      <div
        className="assign_users_modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1>Assign Users</h1>
        <div className="assign_users_list">
          <p className="green">User ID</p>
          <p className="green">username</p>
          <p className="green">action</p>
          {users.map((user) => (
            <AdminUsersItem
              key={user._id}
              user={user}
              assignUser={assignUser}
              adminId={adminId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const Admins = () => {
  const [createAdminModal, setCreateAdminModal] = React.useState(false);
  const [admins, setAdmins] = React.useState([]);
  const [adminActions, setAdminActions] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [showUsers, setShowUsers] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [activeAdmin, setActiveAdmin] = React.useState(null);

  const { users, getUsers } = useUsers();
  const { getAdmins, deleteAdmin, getAdminActions } = useAuth();

  const switchAdminActions = (id, name) => {
    setAdminActions([]);
    setMessage("");
    getAdminActions(id).then((res) => {
      if (res.length === 0) {
        setMessage(name + " hasn't performed any actions yet");
        setTimeout(() => {
          setMessage("");
        }, 2000);
      } else setAdminActions(res);
    });
  };

  const del = (id) => {
    deleteAdmin(id).then(() => {
      getAdmins().then((res) => {
        setAdmins(res);
      });
    });
  };

  const closeModal = () => {
    setCreateAdminModal(false);
    getAdmins().then((res) => {
      setAdmins(res);
    });
  };

  const openAssignModal = (id) => {
    setActiveAdmin(id);
    setOpenModal(true);
  };

  const closeAssignModal = () => {
    setActiveAdmin(null);
    setOpenModal(false);
  };

  useEffect(() => {
    if (users.length > 0) {
      setShowUsers(true);
    }
  }, [users]);

  React.useEffect(() => {
    getAdmins().then((res) => {
      setAdmins(res);
    });
  }, []);
  return (
    <div className="admin_section">
      <div className="row-full-center">
        <h1>Admins</h1>
        <button
          className="edit_modal_form_button"
          onClick={() => setCreateAdminModal(true)}
        >
          Create a new admin
        </button>
      </div>

      {createAdminModal && <CreateAdminModal closeModal={closeModal} />}
      {openModal && (
        <AssignUsersModal
		  getUsers={getUsers}
          users={users}
          adminId={activeAdmin}
          closeModal={() => closeAssignModal()}
        />
      )}
      <div className="admins_container">
        {admins.length > 0 && (
          <div className="admin_list">
            {admins.map((admin) => (
              <div key={admin._id} className="admin_list_item_container">
                <hr />
                <div className="admin_list_item">
                  <p>{admin.username}</p>
                  <div className="actions">
                    {showUsers && (
                      <button
                        className="edit_modal_form_button"
                        onClick={() => openAssignModal(admin._id)}
                      >
                        Assign Users
                      </button>
                    )}
                    <button
                      className="edit_modal_form_button"
                      onClick={() => del(admin._id)}
                    >
                      Delete
                    </button>
                    <button
                      className="edit_modal_form_button"
                      onClick={() =>
                        switchAdminActions(admin._id, admin.username)
                      }
                    >
                      See Actions
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <hr />
          </div>
        )}
        {/* Get user actions here */}
        <div className="actions_section">
          <h1>Admin Actions</h1>
          <div className="actions_list">
            {message && <p className="message">{message}</p>}
            {adminActions.length > 0 && (
              <div className="actions_list">
                {adminActions.map((action) => (
                  <div className="admin_list_item_container">
                    <hr />
                    <div key={action._id} className="admin_list_item">
                      <p>{action.action}</p>
                      <p>{action.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;
