import React from "react";
import useUsers from "../../hooks/useUsers";
import { useAdmin } from "../../hooks/useAuth";

const EditModal = ({ user, refresh, closeModal, create }) => {
  const { isAdmin } = useAdmin();
  const [name, setName] = React.useState(user?.name || "");
  const [email, setEmail] = React.useState(user?.email || "");
  const [verified, setVerified] = React.useState(user?.verified || "true");
  const [status, setStatus] = React.useState(user?.status || "");
  const [totalBalance, setTotalBalance] = React.useState(
    user.totalBalance || 0
  );
  const [password, setPassword] = React.useState("");

  const { updateUser } = useUsers();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (create) {
      if (!password || password === "") {
        alert("Password is required");
        return;
      }
      await updateUser(
        user?._id,
        {
          id: user?._id,
          name,
          email,
          verified: verified === "false" ? false : true,
          totalBalance: Number(totalBalance),
          password,
          status,
        },
        "created a new user: " + name + " with a new balance: " + totalBalance
      );
      closeModal();
      refresh();
      return;
    }
    await updateUser(
      user._id,
      {
        id: user?._id,
        name,
        email,
        verified: verified === "false" ? false : true,
        totalBalance: Number(totalBalance),
        status,
      },
      "updated user: " +
        name +
        " | prev balance: " +
        user.totalBalance +
        " | new balance: " +
        totalBalance
    );
    closeModal();
    refresh();
  };

  React.useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) closeModal();
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <div className="edit_modal" onClick={closeModal}>
      <form
        className="edit_modal_form"
        onSubmit={handleSubmit}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label">Name</label>
          <input
            className="edit_modal_form_input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label">Email</label>
          <input
            className="edit_modal_form_input"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {create && (
          <div className="edit_modal_form_item">
            <label className="edit_modal_form_label">Password</label>
            <input
              className="edit_modal_form_input"
              type="text"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        )}
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label">
            Verified (true - false)
          </label>
          <input
            className="edit_modal_form_input"
            type="text"
            value={verified}
            onChange={(e) => setVerified(e.target.value)}
          />
        </div>
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label">
            Status (profit - loss)
          </label>
          <input
            className="edit_modal_form_input"
            type="text"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
        </div>
        {isAdmin && (
          <>
            <div className="edit_modal_form_item">
              <label className="edit_modal_form_label">Total Balance</label>
              <input
                className="edit_modal_form_input"
                type="text"
                value={totalBalance}
                onChange={(e) => setTotalBalance(e.target.value)}
              />
            </div>
            <div className="edit_modal_form_item">
              <button className="edit_modal_form_button">Save</button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default EditModal;
