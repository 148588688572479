import React from "react";
import useUsers from "../../hooks/useUsers";
import useWalletsDeposits from "../../hooks/useWalletDeposits";
import { useAdmin } from "../../hooks/useAuth";
import { isAssigned } from "../../utils";

const TransactionListHeader = () => {
  return (
    <div className="transaction_list_header">
      <div className="item_wrapper">
        <p className="transaction_list_header_item">Username</p>
      </div>
      <div className="item_wrapper">
        <p className="transaction_list_header_item">Email</p>
      </div>
      <div className="item_wrapper">
        <p className="transaction_list_header_item">Amount</p>
      </div>
      <div className="item_wrapper">
        <p className="transaction_list_header_item">Actions</p>
      </div>
    </div>
  );
};

const TransactionListItem = ({ wallet, openModal }) => {
	const {users} = useUsers();
	const {admin, isAdmin} = useAdmin();

  const [user, setUser] = React.useState(null);
  const [isAdmined, setIsAdmined] = React.useState(false);

  React.useEffect(() => {
	const u = users.filter((item) => item._id === wallet.userId);
	if (u.length > 0) {
		setUser(u[0]);
		setIsAdmined(isAssigned(u[0], admin.id));
	}
  }, [users]);
  if (user?.message === "User not found" || (!isAdmined && !isAdmin)) return <></>;
  return (
    user?.name && (
      <div className="transaction_list_item">
        <div className="item_wrapper">
          <p className="transaction_list_item_text">{user?.name}</p>
        </div>
        <div className="item_wrapper">
          <p className="transaction_list_item_text">{user?.email}</p>
        </div>
        <div className="item_wrapper">
          <p className="transaction_list_item_text">${wallet?.amount}</p>
        </div>
        <div className="item_wrapper">
          <button
            className="transaction_list_item_button"
            onClick={() => openModal(wallet, user)}
          >
            Open
          </button>
        </div>
      </div>
    )
  );
};

const DepositModal = ({
  wallet,
  user,
  closeModal,
  approveWallet,
  rejectWallet,
  deleteWallet,
}) => {
  const { isAdmin, admin } = useAdmin();
  return (
    <div className="transaction_modal_wrapper" onClick={closeModal}>
      <div className="transaction_modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal_header">
          <h1 className="modal_title">Transaction Details</h1>
          <button className="modal_close" onClick={closeModal}>
            close
          </button>
        </div>

        <div className="modal_body">
          <div className="modal_body_item">
            <p className="modal_body_item_label">Username:</p>
            <p className="modal_body_item_text">{user?.name}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Email:</p>
            <p className="modal_body_item_text">{user?.email}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Total Balance:</p>
            <p className="modal_body_item_text">{user?.totalBalance}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Deposit Amount:</p>
            <p className="modal_body_item_text">{wallet?.amount}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Status:</p>
            <p className="modal_body_item_text">{wallet?.status}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Proof hash:</p>
            <p className="modal_body_item_text">{wallet?.proofHash}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Proof image:</p>
          </div>
          {wallet?.proofImage && (
            <img
              className="modal_body_image"
              src={wallet?.proofImage}
              alt=""
              onClick={() => {
                // open image in new tab
                window.open(wallet?.proofImage, "_blank");
              }}
            />
          )}
          {!wallet?.proofImage && (
            <p className="modal_body_item_text">No proof image</p>
          )}
          <div className="modal_cta">
            {wallet.status === "pending" && (
              <button
                onClick={() => {
                  approveWallet(wallet, user);
                  closeModal();
                }}
              >
                Approve
              </button>
            )}
            {wallet.status === "pending" && (
              <button
                onClick={() => {
                  rejectWallet(wallet, user);
                  closeModal();
                }}
              >
                Reject
              </button>
            )}
            {isAdmin && (
              <button
                onClick={() => {
                  deleteWallet(wallet._id, user);
                  closeModal();
                }}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const WithdrawModal = ({
  wallet,
  user,
  closeModal,
  approveWallet,
  rejectWallet,
  deleteWallet,
}) => {
  const { isAdmin } = useAdmin();
  return (
    <div className="transaction_modal_wrapper" onClick={closeModal}>
      <div className="transaction_modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal_header">
          <h1 className="modal_title">Transaction Details</h1>
          <button className="modal_close" onClick={closeModal}>
            close
          </button>
        </div>

        <div className="modal_body">
          <div className="modal_body_item">
            <p className="modal_body_item_label">Username:</p>
            <p className="modal_body_item_text">{user?.name}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Email:</p>
            <p className="modal_body_item_text">{user?.email}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Total Balance:</p>
            <p className="modal_body_item_text">{user?.totalBalance}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Withdraw Amount:</p>
            <p className="modal_body_item_text">{wallet?.amount}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Status:</p>
            <p className="modal_body_item_text">{wallet?.status}</p>
          </div>
          <div className="modal_body_item">
            <p className="modal_body_item_label">Wallet Address:</p>
            <p className="modal_body_item_text">{wallet?.walletAddress}</p>
          </div>
          <div className="modal_cta">
            {wallet.status === "pending" && (
              <button
                onClick={() => {
                  approveWallet(wallet, user);
                  closeModal();
                }}
              >
                Approve
              </button>
            )}
            {wallet.status === "pending" && (
              <button
                onClick={() => {
                  rejectWallet(wallet, user);
                  closeModal();
                }}
              >
                Reject
              </button>
            )}
            {isAdmin && (
              <button
                onClick={() => {
                  deleteWallet(wallet._id, user);
                  closeModal();
                }}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TransactionListBody = ({ type }) => {
  const {
    pendingWallets,
    approveWallet,
    rejectWallet,
    deleteWallet,
    approvedWallets,
    rejectedWallets,
    loading,
  } = useWalletsDeposits(type);

  const [modal, setModal] = React.useState(false);
  const [wallet, setWallet] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const openModal = (wallet, user) => {
    setWallet(wallet);
    setUser(user);
    setModal(true);
  };
  const closeModal = () => setModal(false);
  return (
    <div className="transaction_list_body">
      {modal &&
        (type === "Deposits" ? (
          <DepositModal
            closeModal={closeModal}
            user={user}
            wallet={wallet}
            approveWallet={approveWallet}
            rejectWallet={rejectWallet}
            deleteWallet={deleteWallet}
          />
        ) : (
          <WithdrawModal
            closeModal={closeModal}
            user={user}
            wallet={wallet}
            approveWallet={approveWallet}
            rejectWallet={rejectWallet}
            deleteWallet={deleteWallet}
          />
        ))}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="transaction_list">
            <h1 className="transaction_list_title">Pending Transactions</h1>
            {pendingWallets && pendingWallets.length > 0 ? (
              <>
                <TransactionListHeader />
                <div className="body">
                  {pendingWallets.map((wallet) => (
                    <TransactionListItem
                      openModal={openModal}
                      wallet={wallet}
                      key={wallet._id}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="empty_state">
                <p className="empty_state_text">No pending transactions</p>
              </div>
            )}
          </div>
          <div className="transaction_list">
            <h1 className="transaction_list_title">Approved Transactions</h1>
            {approvedWallets && approvedWallets.length > 0 ? (
              <>
                <TransactionListHeader />
                <div className="body">
                  {approvedWallets.map((wallet) => (
                    <TransactionListItem
                      openModal={openModal}
                      wallet={wallet}
                      key={wallet._id}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="empty_state">
                <p className="empty_state_text">No approved transactions</p>
              </div>
            )}
          </div>
          <div className="transaction_list">
            <h1 className="transaction_list_title">Rejected Transactions</h1>
            {rejectedWallets && rejectedWallets.length > 0 ? (
              <>
                <TransactionListHeader />
                <div className="body">
                  {rejectedWallets.map((wallet) => (
                    <TransactionListItem
                      openModal={openModal}
                      wallet={wallet}
                      key={wallet._id}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="empty_state">
                <p className="empty_state_text">No rejected transactions</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const TransactionListContainer = ({ getWallets, walletSwitch }) => {
  return (
    <div className="transactions_list_wrapper">
      <div className="transactions_header">
        <h1 className="transactions_header_title">Wallet {walletSwitch}</h1>
      </div>
      <div className="transaction_list_container">
        <TransactionListBody type={walletSwitch} />
      </div>
      {/* {walletSwitch === "Deposits" && (
        <div className="transaction_list_container">
          <TransactionListBody type={walletSwitch} />
        </div>
      )}
      {walletSwitch === "Withdraws" && (
        <div className="transaction_list_container">
          <TransactionListBody type={walletSwitch} />
        </div>
      )} */}
    </div>
  );
};

export default TransactionListContainer;
