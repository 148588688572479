import React, { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";

const CreateAdminModal = ({ closeModal }) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [message, setMessage] = React.useState("");
  const { createAdmin } = useAuth();
  const create = async (e) => {
    e.preventDefault();
    if (!username || !password || username === "" || password === "") {
      return setMessage("Please fill in all fields");
    }
    const res = await createAdmin(username, password);
    if (res) {
      //   closeModal();
      setMessage("Admin created successfully");
      setTimeout(() => {
        closeModal();
      }, 1000);
    } else {
      setMessage("Error creating admin");
    }
  };
  //   handle esc key
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [closeModal]);

  return (
    <div className="edit_modal" onClick={closeModal}>
      <form className="edit_modal_form" onClick={(e) => e.stopPropagation()}>
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label" htmlFor="username">
            Username
          </label>
          <input
            className="edit_modal_form_input"
            type="text"
            name="username"
            id="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </div>
        <div className="edit_modal_form_item">
          <label className="edit_modal_form_label" htmlFor="password">
            Password
          </label>
          <input
            className="edit_modal_form_input"
            type="text"
            id="password"
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button className="edit_modal_form_button" onClick={create}>
            Create Admin
          </button>
          {message && message?.length > 0 && (
            <p className="edit_modal_form_message">{message}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateAdminModal;
