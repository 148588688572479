import React from "react";
import { useAuth, useAdmin } from "./useAuth";

const useUsers = () => {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const { admin, isAdmin } = useAdmin();
  const { createAdminAction } = useAuth();

  const server_url = "https://webtrade.onrender.com";
  const route = "/users";

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(server_url + route);
      const json = await response.json();
      setUsers(json);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(server_url + route + "/" + id, {
        method: "DELETE",
      });
      const json = await response.json();
      setUsers(json);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const updateUser = async (id, data, action) => {
    setLoading(true);
    try {
      const response = await fetch(server_url + route, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await response.json();
      !isAdmin &&
        createAdminAction(admin.id, admin.name, admin.name + " has " + action);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  return { users, loading, error, getUsers, deleteUser, updateUser };
};

export default useUsers;
