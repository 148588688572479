import React, { useEffect } from "react";
import {
  People,
  WalletMinus,
  BuyCrypto,
  CardReceive,
  CardSend,
  Logout,
  Security,
} from "iconsax-react";
import { X } from "react-feather";
import { motion } from "framer-motion";
import useUsers from "../hooks/useUsers";
import useWalletsDeposits from "../hooks/useWalletDeposits";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Users, TransactionListContainer, Admins } from "./components";
import { useAdmin } from "../hooks/useAuth";

const useTrading = () => {
  const server_url = "https://webtrade.onrender.com";
  const route = "trade";

  const [trading, setTrading] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const getTrading = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${server_url}/${route}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.error) return setError(data.error);
      setTrading(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const updateTrade = async (body) => {
    setLoading(true);
    try {
      const response = await fetch(`${server_url}/${route}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.error) return setError(data.error);
      setTrading((trading) =>
        trading.map((t) => (t._id === data._id ? data : t))
      );
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const deleteTrade = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${server_url}/${route}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (data.error) return setError(data.error);
      setTrading((trading) => trading.filter((t) => t._id !== id));
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getTrading();
  }, []);

  return { trading, loading, error, getTrading, updateTrade, deleteTrade };
};

const TradingModal = ({ closeModal, trade, user }) => {
  const durations = {
    60: "60s",
    120: "120s",
    3600: "1h",
    43200: "12h",
    604800: "7d",
  };

  const [tradeResult, setTradeResult] = React.useState(null);
  const [profit, setProfit] = React.useState(0);

  const { updateTrade } = useTrading();

  const handleConfirm = async (e) => {
    if (!tradeResult) return;
    e.preventDefault();
    const profitAmount = tradeResult === "profit" ? profit : 0;
    const lossAmount = tradeResult === "loss" ? profit : 0;

    trade.profit = profitAmount;
    trade.loss = lossAmount;
    trade.status = "closed";

    await updateTrade(trade);
    closeModal();
  };
  return (
    <div className="trading_modal_wrapper" onClick={closeModal}>
      <div className="trading_modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal_header">
          <h1 className="modal_header_title">Edit Trade</h1>
          <button className="modal_header_button" onClick={closeModal}>
            Close
          </button>
        </div>

        <div className="modal_body">
          <div className="modal_info">
            <div className="info_item">
              <p className="info_title">Username</p>
              <p className="info_text">{user.name}</p>
            </div>
            <div className="info_item">
              <p className="info_title">Type</p>
              <p className="info_text">{trade.type}</p>
            </div>
            <div className="info_item">
              <p className="info_title">Amount</p>
              <p className="info_text">{trade.limitPrice}</p>
            </div>
            <div className="info_item">
              <p className="info_title">Duration</p>
              <p className="info_text">{durations[trade.duration]}</p>
            </div>
          </div>

          <div className="modal_form">
            <div className="row">
              <h1 className="mini_title">Select trade result</h1>
              <div className="switch_button_group">
                <button
                  onClick={() => setTradeResult("profit")}
                  className={`switch_button ${
                    tradeResult === "profit" && "selected"
                  }`}
                >
                  profit
                </button>
                <button
                  onClick={() => setTradeResult("loss")}
                  className={`switch_button ${
                    tradeResult === "loss" && "selected"
                  }`}
                >
                  loss
                </button>
              </div>
            </div>

            {tradeResult && (
              <input
                type="number"
                className="form_input"
                placeholder={`Add the desired ${tradeResult} amount`}
                onChange={(e) => setProfit(e.target.value)}
              />
            )}
          </div>
          <div className="modal_cta">
            <button
              className={`modal_cta_button ${
                !tradeResult && "disabled_button"
              }`}
              onClick={handleConfirm}
            >
              Confirm Trade
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MiniListHeader = () => {
  return (
    <div className="mini_list_header">
      <div className="item_wrapper">
        <p className="mini_list_header_item">Username</p>
      </div>
      <div className="item_wrapper">
        <p className="mini_list_header_item">Type</p>
      </div>
      <div className="item_wrapper">
        <p className="mini_list_header_item">Status</p>
      </div>
      <div className="item_wrapper">
        <p className="mini_list_header_item">Amount</p>
      </div>
      <div className="item_wrapper">
        <p className="mini_list_header_item">Actions</p>
      </div>
    </div>
  );
};

const MiniListItem = ({ trade, openModal, users, deleteTrade }) => {
  const user = users.find((u) => u._id === trade.userId);
  const { isAdmin } = useAdmin();
  if (!user) return <></>;
  return (
    <div className="mini_list_item">
      <div className="item_wrapper">
        <p className="mini_list_item_text">{user?.name}</p>
      </div>
      <div className="item_wrapper">
        <p className="mini_list_item_text">{trade?.type}</p>
      </div>
      <div className="item_wrapper">
        <p
          className={`mini_list_item_text`}
          style={{ color: trade.status !== "closed" ? "rgb(45, 194, 78)" : "" }}
        >
          {trade.status}
        </p>
      </div>
      <div className="item_wrapper">
        <p className="mini_list_item_text">{trade?.limitPrice}</p>
      </div>
      <div className="item_wrapper">
        <div
          className="buttons"
          style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
        >
          <button
            className={`mini_list_item_button ${
              trade.status === "closed" && "disabled_button"
            }`}
            onClick={() => {
              if (trade.status === "closed") return;
              openModal(trade, user);
            }}
          >
            Open
          </button>
          {isAdmin && (
            <button className={`mini_list_item_button`} onClick={deleteTrade}>
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const TradingWrapper = ({ users }) => {
  const { trading, loading, error, getTrading, deleteTrade } = useTrading();
  const [trade, setTrade] = React.useState({});
  const [user, setUser] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [filteredTrading, setFilteredTrading] = React.useState([]);

  const openModal = (t, u) => {
    setTrade(t);
    setUser(u);
    setModal(true);
  };

  const closeModal = () => setModal(false);

  const searchHandler = (e) => {
    // search by username or email
    setSearch(e.target.value);
    setFilteredTrading(
      trading.filter(
        (trade) =>
          users
            .find((u) => u._id === trade.userId)
            ?.name?.toLowerCase()
            ?.includes(e.target.value.toLowerCase()) ||
          users
            .find((u) => u._id === trade.userId)
            ?.email?.toLowerCase()
            ?.includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div className="trading_wrapper">
      {modal && (
        <TradingModal user={user} trade={trade} closeModal={closeModal} />
      )}
      <div className="trading_header">
        <h1 className="trading_header_title">Trading requests</h1>
        <div className="cta">
          <button className="trading_header_button" onClick={() => {}}>
            Refresh
          </button>
        </div>
      </div>

      <div className="trading_body">
        <input
          type="text"
          className="search"
          placeholder="Search by username or email"
          value={search}
          onChange={searchHandler}
        />
        <MiniListHeader />
        <div className="list_wrapper">
          {filteredTrading.length > 0
            ? filteredTrading.map((trade) => (
                <MiniListItem
                  key={trade._id}
                  trade={trade}
                  openModal={openModal}
                  users={users}
                  deleteTrade={() => {
                    deleteTrade(trade._id);
                  }}
                />
              ))
            : trading.map((trade) => (
                <MiniListItem
                  key={trade._id}
                  trade={trade}
                  open={() => {}}
                  deleteTrade={() => {
                    deleteTrade(trade._id);
                  }}
                  users={users}
                  openModal={openModal}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

const Address = ({ address }) => {
  const server_url = "https://webtrade.onrender.com";
  const route = "/adress";
  const [input, setInput] = React.useState("");
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const inputRef = React.useRef();

  const editAddress = async () => {
    if (!input) {
      setError("Please enter a valid address");
      setTimeout(() => {
        setError(null);
      }, 2000);
      return;
    }
    if (input === address) return;
    try {
      const response = await fetch(server_url + route, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: address._id, hash: input }),
      });
      const json = await response.json();
      if (json.error) return setError(json.error);
      //   setAdress(json.hash);
      setError(null);
      setSuccess("Address updated successfully");
      setTimeout(() => {
        setSuccess(null);
      }, 2000);
    } catch (err) {
      setError("Something went wrong, please try again later.");
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };

  return (
    <div className="wallet_container">
      <h1 className="wallet_address_title">{address.network} Wallet Address</h1>
      <div className="input_container">
        <input
          ref={inputRef}
          type="text"
          value={input || address.hash}
          placeholder="Enter wallet address"
          onChange={(e) => {
            setInput(e?.target?.value);
            inputRef.value = e.target.value;
          }}
          className="wallet_address_input"
        />
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <button className="submit_button" onClick={editAddress}>
          Change address
        </button>
      </div>
    </div>
  );
};

const WalletAdress = ({ addresses }) => {
  return (
    <div className="wallet_address">
      <h1 className="title">Wallets Addresses</h1>
      {addresses?.map((address) => (
        <Address address={address} key={address?._id} />
      ))}
    </div>
  );
};

const capitalize = (str) => {
  if (!str) return "";
  return str?.charAt?.(0)?.toUpperCase() + str?.slice(1);
};

const DashboardListItem = ({ title, icon, action, mainView }) => {
  return (
    <li
      onClick={() => action(title)}
      className={`dashboard_list_item ${mainView === title ? "selected" : ""}`}
    >
      <span className="dashboard_list_item_icon">{icon}</span>
      <h3 className="dashboard_list_item_title">{capitalize(title)}</h3>
    </li>
  );
};

const variants = {
  hidden: { left: "-100vw", opacity: 0 },
  visible: { left: 0, opacity: 1 },
};

const SideBar = ({
  dashboardList,
  mainView,
  setSearchParams,
  drawer,
  close,
}) => {
  const action = (item) => {
    setSearchParams({ view: item });
    close();
  };

  const { logout } = useAuth();
  return (
    <motion.aside
      className="dashboard_sidebar_container"
      variants={variants}
      animate={drawer ? "visible" : "hidden"}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      initial="hidden"
    >
      <div className="top">
        <div className="logo_section">
          <a href="/dashboard">
            <img src="/logo.svg" alt="logo" className="logo" />
          </a>
          {window.innerWidth <= 900 && (
            <X size={26} color="white" onClick={close} />
          )}
        </div>
        <ul className="dashboard_list">
          {dashboardList.map(({ title, icon }) => {
            return (
              <DashboardListItem
                key={title}
                title={title}
                icon={icon}
                action={action}
                mainView={mainView}
              />
            );
          })}
        </ul>
      </div>
      <div className="bottom">
        <DashboardListItem title={"Logout"} icon={<Logout />} action={logout} />
      </div>
    </motion.aside>
  );
};

const useWalletAddress = () => {
  const server_url = "https://webtrade.onrender.com";
  const route = "/adress";

  const [addresses, setAddresses] = React.useState([]);

  React.useEffect(() => {
    const getAdress = async () => {
      try {
        const response = await fetch(server_url + route);
        const json = await response.json();
        setAddresses(json);
      } catch (err) {
      }
    };
    getAdress();
  }, []);

  return { addresses };
};

const Dashboard = () => {
  document.title = "WT Admin - Dashboard";
  const auth = window.sessionStorage.getItem("auth");
  if (!auth) window.location.href = "/";

  const { getUsers, users: u } = useUsers();
  const [users, setUsers] = React.useState([]);
  const { getWallets } = useWalletsDeposits();
  const { addresses } = useWalletAddress();

  const [searchParams, setSearchParams] = useSearchParams();
  const [mainView, setMainView] = React.useState("Users");
  const [drawer, setDrawer] = React.useState(
	window.innerWidth <= 900 ? false : true
  );
  const close = () => {
	window.innerWidth <= 900 && setDrawer(false);
  };
  
  const { isAdmin, admin } = useAdmin();
  
  const [dashboardList, setDashboardList] = React.useState([]);

	//   TODO
  React.useEffect(() => {
	if (!isAdmin){let adminUsers = [];
	u.forEach((item) => {
		item.admins.forEach((a) => {
			if (a === admin.id) {
				adminUsers.push(item);
			}
		})
	});
	setUsers(adminUsers);} else setUsers(u);
  }, [u]);

  React.useEffect(() => {
    setInterval(() => {
      getUsers();
      getWallets();
    }, 4000);
  }, []);


  useEffect(() => {
    let dl = [];
    dl.push({ title: "users", icon: <People /> });
    if (isAdmin) dl.push({ title: "wallets", icon: <WalletMinus /> });
    if (isAdmin) dl.push({ title: "admins", icon: <Security /> });
    dl.push({ title: "trading", icon: <BuyCrypto /> });
    dl.push({ title: "deposits", icon: <CardReceive /> });
    dl.push({ title: "withdraws", icon: <CardSend /> });
    setDashboardList(dl);
  }, [isAdmin]);

  useEffect(() => {
    const view = searchParams.get("view");
    if (view) {
      setMainView(view);
    }
    document.title = `Webtrade - ${view ? capitalize(view) : "Dashboard"}`;
  }, [searchParams]);

  return (
    <div className="dashboard_container">
      <SideBar
        dashboardList={dashboardList}
        setMainView={setMainView}
        setSearchParams={setSearchParams}
        mainView={mainView}
        drawer={drawer}
        close={close}
      />
      <div className="dashboard_main">
        {mainView === "users" && <Users getUsers={getUsers} users={users} />}
        {mainView === "wallets" && isAdmin && (
          <WalletAdress addresses={addresses} />
        )}
        {mainView === "admins" && isAdmin && <Admins />}
        {mainView === "trading" && <TradingWrapper users={users} />}
        {(mainView === "deposits" || mainView === "withdraws") && (
          <TransactionListContainer
            getWallets={getWallets}
            walletSwitch={capitalize(mainView)}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
