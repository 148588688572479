import React from "react";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = React.useState(null);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const [auth, setAuth] = React.useState(null);
  const [error, setError] = React.useState("");
  const session = window.sessionStorage.getItem("auth");
  if (session && !auth) {
    setAuth(session);
  }

  const [token, setToken] = React.useState("");
  const [username, setUsername] = React.useState("");

  const server_url = "https://webtrade.onrender.com";
  const route = "admin";

  const createAdmin = async (username, password) => {
    const res = await fetch(`${server_url}/${route}/admins`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (!json.error) {
      return true;
    }
    return false;
  };

  const getAdmins = async () => {
    const res = await fetch(`${server_url}/${route}/admins`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (!json.error) {
      return json;
    }
    return [];
  };

  const deleteAdmin = async (id) => {
    const res = await fetch(`${server_url}/${route}/admins/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (!json.error) {
      return true;
    }
    return false;
  };
  // router.get("/actions/:adminId", getAdminActions);
  const getAdminActions = async (id) => {
    const res = await fetch(`${server_url}/${route}/actions/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (!json.error) {
      return json;
    }
    return [];
  };

  const createAdminAction = async (adminId, adminName, action) => {
    const res = await fetch(`${server_url}/${route}/actions`, {
      method: "POST",
      body: JSON.stringify({ adminId, adminName, action }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (!json.error) {
      return true;
    }
    return false;
  };

  const login = async (e) => {
    e.preventDefault();
    const res = await fetch(`${server_url}/${route}`, {
      method: "POST",
      body: JSON.stringify({ token: token, admin: username }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await res.json();
    if (!json.error) {
      setAuth(json.user);
      window.sessionStorage.setItem("auth", true);
      window.sessionStorage.setItem("user", JSON.stringify(json));
    }
    setError(json.error);
  };

  const logout = () => {
    setAuth(null);
    window.sessionStorage.removeItem("auth");
    window.sessionStorage.removeItem("user");
  };

  return {
    auth,
    login,
    logout,
    token,
    setToken,
    error,
    setError,
    username,
    setUsername,
    createAdmin,
    getAdmins,
    deleteAdmin,
    getAdminActions,
    createAdminAction,
  };
};

export const useAdmin = () => {
  const [admin, setAdmin] = React.useState(null);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const getAdmin = async () => {
    const user = JSON.parse(window?.sessionStorage?.getItem("user"));
    setAdmin(user);
    setIsAdmin(user?.type === "admin");
  };

  React.useEffect(() => {
    getAdmin();
  }, []);

  return { admin, isAdmin };
};
